<template>
  <v-card class="elevation-0" style="border-radius:16px;">
    <v-toolbar
      dark
      color="blue darken-3"
      class="mb-1"
      elevation="0"
      style="
        border-top-left-radius: 16 !important;
        border-top-right-radius: 16 !important;
      "
    >
      <span class="font-weight-bold font-size-h4" style="width:15%">{{
        $t("MF.prescriptionLabel")
      }}</span>
      <v-spacer style="width:30%"></v-spacer>
      <v-select
        flat
        solo-inverted
        hide-details
        :items="keys"
        prepend-inner-icon="mdi-magnify"
        :label="$t('MF.sortby')"
        v-model="sortBy"
        class="mr-4"
        v-if="false"
      ></v-select>
      <v-text-field
        v-model="search"
        clearable
        flat
        dense
        rounded
        solo-inverted
        hide-details
        prepend-inner-icon="mdi-magnify"
        :label="$t('MF.search')"
      ></v-text-field>
    </v-toolbar>
    <v-data-table
      :items="itemsSorted"
      :loading="loadingStatus"
      :locale="this.$root.lang"
      :headers="headers"
      style="border-radius:16px;"
      :items-per-page="5"
      :loading-text="$t('tLoading')"
      :search="search"
      id="my-table"
    >
      <template v-slot:item.owner.first_name="{ item }">
        <Avatar :user="item.owner"></Avatar>
      </template>
      <template v-slot:item.medication="{ item }">
        <div>
          <b-button
            v-b-toggle="'collapse-' + item.id"
            class="mb-1"
            style="width:30rem;"
            >{{ item.medication.length }}
            {{ $t("MF.prescription.medication") }}</b-button
          >
          <b-collapse :id="'collapse-' + item.id">
            <div class="p-0">
              <div
                v-for="(med, idx) in item.medication"
                style="width:30rem;"
                :key="idx"
                class="listitem mb-2"
              >
                <v-list-item three-line disabled>
                  <v-list-item-avatar size="47" color="#F2F7FD">
                    <v-img
                      v-if="med.type == 'injection'"
                      src="/media/svg/icons/Doctory/icons-syringe.svg"
                      height="20"
                      width="20"
                      contain
                    />
                    <v-img
                      v-else
                      src="/media/svg/icons/Doctory/icons-pills.svg"
                      height="20"
                      width="20"
                      contain
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      ><div class="listitemtitle">
                        {{ med.intake }} {{ med.type }} of {{ med.dosage }}
                        <span class="font-weight-bold">{{
                          med.medicine.name
                        }}</span>
                      </div></v-list-item-title
                    >
                    <v-list-item-subtitle>
                      <span
                        v-for="(time, index) in med.times"
                        :key="time"
                        class="font-size-h6"
                        >{{ time }}
                        <span v-if="index < med.times.length">, </span></span
                      >

                      <span class="font-size-h6"> {{ med.frequancy }}</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <div class="listitemsubtitle">
                        Expires
                        {{ med.end_date | moment("dddd, MMMM Do YYYY") }}
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </div>
          </b-collapse>
        </div>
      </template>

      <template v-slot:item.notes="{ item }">
        <span class="text-dark-75 font-weight-bold font-size-sm mr-2">{{
          item.notes
        }}</span>
      </template>
      <template v-slot:item.created_on="{ item }">
        <span class="text-dark-75 font-weight-bold font-size-sm mr-2">{{
          item.created_on | moment("dddd, MMMM Do YYYY")
        }}</span>
      </template>
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-space-around">
          <v-btn
            class="mx-2"
            small
            fab
            elevation="0"
            color="rgba(165, 203, 239, 0.20)"
            @click="
              toexport = item;
              openpdfview = true;
            "
          >
            <unicon
              style="margin-right: auto;margin-left: auto;"
              name="cloud-download"
              fill="#0971CE"
              height="20"
            ></unicon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <v-navigation-drawer
      v-model="openpdfview"
      app
      temporary
      fixed
      :width="$root.mobile ? '100vw' : '40vw'"
      :right="$root.lang != 'ar'"
      scrollable
    >
      <v-card v-if="toexport != null" elevation="0">
        <v-toolbar flat dark color="primary">
          <v-btn icon dark @click="openpdfview = !openpdfview">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t("MF.prescription.title") }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon dark @click="generatePrescription">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </v-toolbar>
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="false"
          :paginate-elements-by-height="1400"
          filename="Prescription"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          @progress="onProgress($event)"
          @hasStartedGeneration="hasStartedGeneration()"
          @hasGenerated="hasGenerated($event)"
          ref="html2Pdf"
        >
          <section slot="pdf-content">
            <v-card-text class="p-10">
              <div
                style="display:flex; flex-direction:column; "
                class="text-primary mb-10"
              >
                <img
                  width="200px"
                  src="/media/logos/logo_text.png"
                  alt="Logo"
                />
                <div class="font-size-h1 pt-7 font-weight-boldest">
                  {{ $t("MF.prescription.title") }}
                </div>
                <div class="font-size-h6 pt-4">
                  {{ $t("APPOINTMENTS.add.dr") }}
                  {{ toexport.owner.first_name }}
                  {{ toexport.owner.last_name }}
                </div>
                <div class="font-size-h6 pt-2">
                  {{ toexport.created_on | moment("dddd, MMMM Do YYYY") }}
                </div>
                <v-divider />
              </div>

              <div
                v-for="(med, idx) in toexport.medication"
                :key="idx"
                class=" mb-10"
              >
                <div style="display:flex; flex-direction:column;">
                  <div class="font-size-h4 pb-3">
                    {{ med.intake }} {{ med.type }} of {{ med.dosage }}
                    <span class="font-weight-bold">{{
                      med.medicine.name
                    }}</span>
                  </div>
                  <div class="pb-3">
                    <span class="font-size-h6 font-weight-bolder">
                      {{ med.frequancy }}:
                    </span>
                    <span
                      v-for="(time, index) in med.times"
                      :key="time"
                      class="font-size-h6"
                      >{{ time }}
                      <span v-if="index < med.times.length - 1">, </span></span
                    >
                  </div>
                  <div class="font-size-h6">
                    Expires
                    {{ med.end_date | moment("dddd, MMMM Do YYYY") }}
                  </div>
                </div>
              </div>
              <div class="font-size-h6 pb-4 text-primary">
                {{ $t("MF.notesLabel") }}
              </div>
              <div class="">{{ toexport.notes }}</div>
            </v-card-text>
          </section>
        </vue-html2pdf>
        <v-card-text class="p-10">
          <div
            style="display:flex; flex-direction:column; "
            class="text-primary mb-10"
          >
            <img width="200px" src="/media/logos/logo_text.png" alt="Logo" />
            <div class="font-size-h1 pt-7 font-weight-boldest">
              {{ $t("MF.prescription.title") }}
            </div>
            <div class="font-size-h6 pt-4">
              {{ $t("APPOINTMENTS.add.dr") }} {{ toexport.owner.first_name }}
              {{ toexport.owner.last_name }}
            </div>
            <div class="font-size-h6 pt-2">
              {{ toexport.created_on | moment("dddd, MMMM Do YYYY") }}
            </div>
            <v-divider />
          </div>

          <div
            v-for="(med, idx) in toexport.medication"
            :key="idx"
            class=" mb-10"
          >
            <div style="display:flex; flex-direction:column;">
              <div class="font-size-h4 pb-3">
                {{ med.intake }} {{ med.type }} of {{ med.dosage }}
                <span class="font-weight-bold">{{ med.medicine.name }}</span>
              </div>
              <div class="pb-3">
                <span class="font-size-h6 font-weight-bolder">
                  {{ med.frequancy }}:
                </span>
                <span
                  v-for="(time, index) in med.times"
                  :key="time"
                  class="font-size-h6"
                  >{{ time }}
                  <span v-if="index < med.times.length - 1">, </span></span
                >
              </div>
              <div class="font-size-h6">
                Expires
                {{ med.end_date | moment("dddd, MMMM Do YYYY") }}
              </div>
            </div>
          </div>
          <div class="font-size-h6 pb-4 text-primary">
            {{ $t("MF.notesLabel") }}
          </div>
          <div class="">{{ toexport.notes }}</div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import Avatar from "@/view/pages/utilComponents/avatar2.vue";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "Prescription",
  props: ["mfid"],
  components: { Avatar, VueHtml2pdf },
  data() {
    return {
      search: "",
      sortBy: "",
      error: "",
      keys: ["All"],
      loadingStatus: true,
      items: [],
      toexport: null,
      openpdfview: false,
      //historySorted:[],
      headers: [
        {
          text: this.$t("MF.by"),
          align: "left",
          sortable: true,
          value: "owner.first_name"
        },
        { text: this.$t("MF.prescription.medication"), value: "medication" },
        { text: this.$t("MF.prescription.notes"), value: "notes" },
        { text: this.$t("MF.date"), value: "created_on" },
        {
          text: this.$t("MF.action"),
          value: "action",
          align: "center",
          sortable: false
        }
      ],
      lastSelected: null,
      pdfItems: [
        { title: "Item 1", body: "I am item 1 body text" },
        { title: "Item 2", body: "I am item 2 body text" },
        { title: "Item 3", body: "I am item 3 body text" },
        { title: "Item 4", body: "I am item 4 body text" }
      ],
      moreText: [
        "This is another few sentences of text to look at it.",
        "Just testing the paragraphs to see how they format.",
        "jsPDF likes arrays for sentences.",
        "Do paragraphs wrap properly?",
        "Yes, they do!",
        "What does it look like?",
        "Not bad at all."
      ]
    };
  },
  mounted() {
    this.getItems();
    //this.getTypes();
  },
  methods: {
    async getItems() {
      //var userInfo = JSON.parse(localStorage.userInfo).id;
      this.items = await this.$prescription.getUserPrescriptions(this.mfid);
      this.loadingStatus = false;
    },
    generatePrescription() {
      this.$refs.html2Pdf.generatePdf();
    },
    onProgress() {
      // console.log("progress ", e);
    },
    hasStartedGeneration() {
      // console.log("started genration");
    },
    hasGenerated() {
      // console.log("has generated", e);
    }
    /*
    exportPDF(item) {
      const doc = new jsPDF();
      let userName =
        "Name: " + item.owner.first_name + " " + item.owner.last_name;

      doc
        .setFont("helvetica")
        .setFontSize(12)
        .text(userName, 14, 14);

      let i = 1;
      let medBody = [];
      for (let med of item.medication) {
        const medItem = [];
        medItem.push(i++);
        medItem.push(med.medicine.name);
        medItem.push(med.intake);
        medItem.push(med.type);
        medItem.push(med.start_date);
        medItem.push(med.end_date);
        medItem.push(med.dosage);
        medItem.push(med.frequancy);
        let t = [];
        for (let time of med.times) {
          t.push(time);
        }
        medItem.push(t);

        medBody.push(medItem);
      }

      doc.autoTable({
        head: [
          [
            "#",
            "Medicine",
            "Intake",
            "type",
            "Start Date",
            "End Date",
            "Dosage",
            "Frequency",
            "Times",
          ],
        ],
        body: medBody,
        margin: { top: 25 },
      });

      doc
        .setFont("helvetica")
        .setFontSize(12)
        .text("Notes: " + item.notes, 14, 20);

      doc
        .setFont("times", "italic")
        .setFontSize(11)
        // .setFontStyle('italic')
        .setTextColor(0, 0, 255)
        .text("@Doctory", 14, doc.internal.pageSize.height - 14);

      doc.save("medical file.pdf");
    },*/
  },
  computed: {
    itemsSorted() {
      return this.items.filter(i => {
        return (
          !this.sortBy ||
          i.vital_type.name === this.sortBy ||
          this.sortBy == "All"
        );
      });
    }
  }
};
</script>
